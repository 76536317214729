import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./global.scss";

const App = React.lazy(() => import("./App"));

const pre = (
  <div className="preload">
    <div className="loader"></div>
  </div>
);

ReactDOM.render(
  <Suspense fallback={pre}>
    <App />
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
